import { useInView } from "react-intersection-observer";
import { HomePageContext } from "/context/HomePageContext";
import { useContext } from "react";
import DataHandler from "../../Skeleton";
import Link from "next/link";
import Image from "/components/CustomImage";

const ReadKamaBlogDynamic = async (data) => {
  const gaModule = await import('/lib/ga');
  gaModule.ReadKamaBlog(data);
};

const BelowBanner = ({ styles, eventCommonObj }) => {
  const [{ isMobileType, cms_data }] = useContext(HomePageContext);
  const { ref: section7Ref, inView: section7View } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "0px 0px 200px 0px"
  })

  return (
    <div className={`${styles.home_mobile_sec7}`} ref={section7Ref} style={{ minHeight: isMobileType ? '58rem' : '37rem' }}>
      <div className="row">
        {/* <DataHandler condition={section7View} height={isMobileType ? 350 : 518}>
                <div className="col-12 col-md-6">
                  <h2>
                    <span> {cms_data.homepage_banner_others_text}</span>
                  </h2>
                </div>
              </DataHandler>
              <DataHandler condition={section7View} height={isMobileType ? 450 : 518}>
                <div id="banner_6" className="col-12 col-md-6">
                  <abbr ref={containerRef}>
                    {lockRef.current && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: cms_data.homepage_banner_promotion6_image,
                        }}
                      />
                    )}
                  </abbr>
                  <h3 className={styles.pro_h3}>
                    {cms_data.homepage_banner_promotion6_title}
                  </h3>
                  <p>{cms_data.homepage_banner_promotion6_text}</p>
                  <Link href={cms_data.homepage_banner_promotion6_link}>
                    <a onClick={() => ga.BookFreeSession('virtual_consultation', eventCommonObj)}>{cms_data.homepage_banner_promotion6_extra}</a>
                  </Link>
                </div>
              </DataHandler> */}

        <DataHandler condition={section7View} height={isMobileType ? 450 : 518}>
          <div id="banner_7" className="col-12 col-md-6">
            <span>
              <Link href={cms_data.homepage_banner_promotion7_link}>

                <Image
                  loading='lazy'
                  src={`${cms_data.base_media_url}homepage_images/${cms_data.homepage_banner_promotion7_image}`}
                  alt={cms_data.homepage_banner_promotion7_title} title={cms_data.homepage_banner_promotion7_title} placeholder="blur" blurDataURL="/images/kama-placeholder.jpg"
                  width="550" height="350"
                />

              </Link>
            </span>
            <h3>{cms_data.homepage_banner_promotion7_title}</h3>
            <p>{cms_data.homepage_banner_promotion7_text}</p>
            <Link className={styles.two_banner_btn} href={cms_data.homepage_banner_promotion7_link}>
              {cms_data.homepage_banner_promotion7_extra}
            </Link>
          </div>
        </DataHandler>

        <DataHandler condition={section7View} height={isMobileType ? 450 : 518}>
          <div id="banner_8" className="col-12 col-md-6" >
            <span>
              <Link href={cms_data.homepage_banner_promotion8_link}>

                <Image
                  loading='lazy'
                  src={`${cms_data.base_media_url}homepage_images/${cms_data.homepage_banner_promotion8_image}`}
                  alt={cms_data.homepage_banner_promotion8_title} title={cms_data.homepage_banner_promotion7_title} placeholder="blur" blurDataURL="/images/kama-placeholder.jpg"
                  width="550" height="350"
                />

              </Link>
            </span>
            <h3>{cms_data.homepage_banner_promotion8_title}</h3>
            <p>{cms_data.homepage_banner_promotion8_text}</p>
            <Link
            className={styles.two_banner_btn}
              href={cms_data.homepage_banner_promotion8_link}
              onClick={() => ReadKamaBlogDynamic(eventCommonObj)}>
              {cms_data.homepage_banner_promotion8_extra}
            </Link>
          </div>
        </DataHandler>
      </div>
    </div>
  );
}

export default BelowBanner;